'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { CenteredLoader } from '@/components';

export default function NotFound(): JSX.Element {
  const router = useRouter();

  useEffect(() => {
    router.push('/');
  }, []);

  return <CenteredLoader />;
}
